
import { Company } from '../interface/company';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, map } from 'rxjs/operators';
import { CompanyUtilities } from '../class/company-utilities';
import { DataService } from './data.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { firstValueFrom } from 'rxjs';
import { Utilities } from '../class/utilities';
import { IInforcarQueriesResponse } from '../interface/inforcar-queries-response';
import { ObjectId } from '../shared/type-aliases/object-id';
import { IsoString } from '../shared/type-aliases/iso-string';

interface TCarKey {
  id: ObjectId,
  description: string,
  key: string,
  ip: Array<string>,
  createdAt: IsoString,
  updatedAt: IsoString
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpClient,
    private storage: AngularFireStorage,
    private dataService: DataService,
  ) { }

  async get(id: string, force = false): Promise<Company> {
    if (!force && this.dataService.user) {
      const owns = await firstValueFrom(this.dataService.user.ownCompanies$);
      const linkeds = await firstValueFrom(this.dataService.user.ownCompanies$);
      const company = owns.concat(linkeds).find(c => c.id === id);
      if (company) {
        return company;
      }
    }
    const url = `${environment.mkgoURL}/api/v1/companies/${id}`;
    const response: any = await this.http.get(url).pipe(first()).toPromise();
    return CompanyUtilities.complyApp(response.company);
  }

  /**
   * @param force If you want full data, set it to true
   */
  async getByCnpj(cnpj: string, force = false): Promise<Company> {
    if (!cnpj) {
      throw new Error('CNPJ não informado')
    }
    let company: Company;
    if (this.dataService.user) {
      const owns = await firstValueFrom(this.dataService.user.ownCompanies$);
      const linkeds = await firstValueFrom(this.dataService.user.ownCompanies$);
      company = owns.concat(linkeds).find(c => Utilities.equalUnmasked(c.cnpj, cnpj))
      if (!force) {
        return company;
      }
    }
    const unmasked = Utilities.removeMask(cnpj);
    const url = `${environment.mkgoURL}/api/v1/companies/cnpj/${unmasked}`;
    company = await firstValueFrom(this.http.get<{ company: Company }>(url).pipe(first(), map(r => r.company)));
    if (!force) {
      return company
    }
    const fullCompany = await this.get(company.id, true);
    return fullCompany;
  }

  /** find current user companies */
  async getAll(): Promise<Company[]> {
    const url = `${environment.mkgoURL}/api/v1/companies/user`;
    const response: any = await this.http.get(url).pipe(first()).toPromise();
    (response.companies as Company[]).forEach(company => CompanyUtilities.complyApp(company));
    return (response.companies as Company[]).reverse();
  }

  async add(company: Company): Promise<void> {
    const apiCompany = CompanyUtilities.complyApi(company);
    apiCompany['cashier'] = false;
    const url = `${environment.mkgoURL}/api/v1/companies`;
    const response = await firstValueFrom(this.http.post<{ id: ObjectId }>(url, apiCompany));
    company['id'] = response.id;
    if (company.companyLogoBlob) {
      await this.updateCompanyLogo(company);
    }
    if (company.associationLogoBlob) {
      await this.updateAssociationLogo(company);
    }
  }

  async update(company: Company): Promise<Company> {
    if (company.companyLogoBlob) {
      await this.updateCompanyLogo(company);
    }
    if (company.associationLogoBlob) {
      await this.updateAssociationLogo(company);
    }
    let apiCompany = CompanyUtilities.complyApi(company);
    const url = `${environment.mkgoURL}/api/v1/companies/${company.id}`;
    const header = {
      headers: new HttpHeaders()
        .append('Company-CNPJ', company.cnpj)
        .append('Content-Type', 'application/json')
    }
    return firstValueFrom(this.http.put<Company>(url, apiCompany, header));
  }



  public setInvoiceNumber(cnpj: string, field: "numberInvoice" | "numberGerencial" | "numberInvoiceNFC" | "numberInvoiceNFS", newValue: number){
    const url = `${environment.mkgoURL}/api/v1/companies-publics/numberInvoice`;
    const body = { cnpj };
    Object.defineProperty(body, field, {
      value: newValue, 
      enumerable: true
    });

    const headers = new HttpHeaders().append('Content-Type', 'application/json')
    return this.http.post(url, body, { headers });
  }

  updateFavorite(id: string, favorite: number): Promise<Company> {
    const url = `${environment.mkgoURL}/api/v1/companies/${id}/favorite/${favorite}`;
    return firstValueFrom(this.http.post<Company>(url, ''));
  }


  async join(body: { cnpj: string }): Promise<Company> {
    const url = `${environment.mkgoURL}/api/v1/companies/request`;
    const headers = new HttpHeaders().append("Content-Type", "application/json");
    return firstValueFrom(this.http.post<Company>(url, JSON.stringify(body), { headers }));
  }


  /** Returns how many requests from InfoCarAPI this company have contracted to use in a interval */
  async getInfocarRequestsContracted(): Promise<number> {
    const url = `${environment.mkgoURL}/api/v1/companies/general/infoCarQuery`;
    const options = await firstValueFrom(this.dataService.httpOptions(false));
    const resp = await firstValueFrom(
      this.http.get<{ _id: ObjectId, cnpj: string, infoCarQuery: number }>(url, options));
    return resp.infoCarQuery;
  }

  /** Returns how many requests of vehicle-plate this company has made using InfoCarAPI 
   * @param start The start date
   * @param end The end date
  */
  async getInfocarAmountRequestsMade(start: Date, end: Date): Promise<number> {
    const startString = start.toISOString().split('T')[0]
    const endString = end.toISOString().split('T')[0]
    const url = `${environment.mkgoURL}/api/v1/infocar?startDate=${startString}&endDate=${endString}&cnpj=true`;
    const options = await firstValueFrom(this.dataService.httpOptions(false));
    const resp: {
      amount: number
      /** the company CNPJ */
      _id: string
    }[] = await this.http.get(url, options).pipe(first()).toPromise() as any;
    return resp.length ? resp[0].amount : 0;

  }

  async getInfocarRequestsMade(start: Date, end: Date): Promise<IInforcarQueriesResponse[]> {
    const startString = start.toISOString().split('T')[0]
    const endString = end.toISOString().split('T')[0]
    const url = `${environment.mkgoURL}/api/v1/infocar/cnpj?startDate=${startString}&endDate=${endString}`;
    const options = await firstValueFrom(this.dataService.httpOptions(false));
    const response = await firstValueFrom(this.http.get(url, options));
    return response as any;
  }

  /** Return true if current company can made requests on infoCarAPI */
  async canRequestInfoCar(): Promise<boolean> {
    let resp: boolean;
    try {
      [this.dataService.company.infoCarContracted, this.dataService.company.infoCarQuery] = await Promise.all([
        this.getInfocarRequestsContracted(),
        this.getInfocarAmountRequestsMade(new Date(new Date().setDate(1)), new Date())
      ]);
      resp = (this.dataService.company.infoCarContracted > this.dataService.company.infoCarQuery);
      return resp;
    } catch (error) {
      console.warn("\nERRO AO CONSULTAR DADOS INFOCAR DA EMPRESA\n\n", error)
      return false;
    }
  }

  async getCompanyURL(company: Company) {
    const path = `${company.id}/logos/${company.id}companyLogo.jpg`;
    return this.storage.ref(path).getDownloadURL().toPromise()
  }

  private async updateCompanyLogo(company: Company): Promise<void> {
    const path = `${company.id}/logos/${company.id}companyLogo.jpg`;
    await this.storage.upload(path, company.companyLogoBlob);
    company.companyLogo = await this.storage.ref(path).getDownloadURL().toPromise();
  }

  private async updateAssociationLogo(company: Company): Promise<void> {
    const path = `${company.id}/logos/${company.id}associationLogo.jpg`;
    await this.storage.upload(path, company.associationLogoBlob);
    company.associateLogo = await this.storage.ref(path).getDownloadURL().toPromise();
  }

  public async getLogo(cnpj: string): Promise<Blob> {
    const url = `${environment.mkgoURL}/api/v1/companies/logo`;
    const options = {
      headers: { 'Company-CNPJ': cnpj }, responseType: 'blob' as 'blob'
    };
    const resp = await this.http.get(url, options).pipe(first()).toPromise();
    return resp
  }

  /**
   * At integrating a new Tcar-integrated company, the TCar system will need a token to comunicate
   */
  public generateIntegrationTokenFor(company: Partial<Company> & { cnpj: string }) {
    const url = `${environment.mkgoURL}/api/v1/companies/keys`;
    const body = { "description": "MKGOService-1", "ip": [] }; // default
    const headers = new HttpHeaders().append("Company-Cnpj", company.cnpj)
    return this.http.post<{ key: TCarKey }>(url, body, { headers })
      .pipe(
        first(),
        map(resp => resp.key),
        map(key => btoa(`${key.id}:${key.key}`))
      );
  }
}
