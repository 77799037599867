import { toASCII } from "punycode";
import { Company } from "../interface/company";
import { AddressUtilities } from "./address-utilities";
import { Utilities } from "./utilities";

export class CompanyUtilities extends Utilities {

  static complyApp(company: Company): Company {
    company.deduNFE = Boolean(company.deduNFE);
    company.deduNFSE = Boolean(company.deduNFSE);
    company.deduISS = Boolean(company.deduISS);
    company.fancyName = (company.fancyName || '').trim();
    company.crt = (company.crt);
    company.sendCode = (company.sendCode);
    company.address = company.address || {};
    company.stateRegistration = (company.stateRegistration || '').trim();
    company.email = toASCII((company.email || '').trim());
    company.companyLogo = (company.companyLogo || '').trim();
    company.associateLogo = (company.associateLogo || '').trim();
    company.ISS = Number(company.ISS) || 0;
    return company;
  }

  static complyApi(company: Company): Company {
    let apiCompany = { ...company };
    for (const key of Object.keys(apiCompany)) {
      const value = apiCompany[key];
      if (value == null || value == undefined) {
        delete apiCompany[key];
      }
    }
    const nsu = company.lastNSU || "1";
    apiCompany["lastNSU"] = nsu.padStart(15, '0');
    apiCompany["migrateIntegrated"] = Boolean(company.migrateIntegrated);
    apiCompany["controlStock"] = Boolean(company.controlStock);
    apiCompany['ISS'] = Number(apiCompany['ISS']) || ('0' as any);
    delete apiCompany['companyLogoBlob'];
    delete apiCompany['companyLogoInput'];
    delete apiCompany['associationLogoBlob'];
    delete apiCompany['associationLogoInput'];
    return apiCompany
  }

}
